import MediaRenderer from "@/mvcComponents/Commons/MediaRenderer/MediaRenderer.view";
import React from "react";

const BannerImage = ({ image }) => {
  return (
    <MediaRenderer
      image={image}
      url={image?.url}
      width={400}
      height={400}
      alt={image?.url?.split("/")?.at(-1) || "Avatar Missing"}
      borderRadius="0.2rem"
    />
  );
};

export default BannerImage;
